'use client';

import { Box } from '@mui/material';
import { Carousel } from 'nuka-carousel';
import React, { ReactNode } from 'react';

interface Props {
  elements: ReactNode[];
}

const MobileSwiper = ({ elements }: Props) => {
  return (
    <Carousel scrollDistance="slide" showDots>
      {elements.map((element, index) => {
        return (
          <Box minWidth="100%" key={index}>
            {element}
          </Box>
        );
      })}
    </Carousel>
  );
};

export default MobileSwiper;
